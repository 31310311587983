// // Liana Wireframe Variables
// // =============================================================================
// // Background
// $canvas-bg: #fff;
//
// // Border
// $border-color: $gray-light;
//
// // Box Shadow
// $box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
//
// // List
// $list-custom-bullet: "•";
// $list-custom-bullet-color: $brand-primary;
//
// // Loader
// $loader-color: $gray-dark;
// $loader-bg: $body-bg;
//
// Sticky Footer
// $footer-height: 50px !default;
// $sticky-footer-enabled: false !default;
.header {
  box-shadow: 0 5px 8px rgba(15, 85, 144, .2);
  z-index: 1;
  position: relative;
}
.pin-card {
  margin-top: 40px;
}
.pin-card-img-top {
  display: block;
  width: 80px;
  height: 80px;
	line-height: 45px;
  margin: -40px auto 0;
}
.form-field {
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
}
.heading-short-line {
  width: 100px;
  height: 3px;
  display: block;
  margin: 1.5rem auto;
  background: #292b2c;
}
@media (min-width: 576px) {
  .logo {
    position: absolute;
    top: -1.5rem;
    left: 0;
  }
}
