.border{
	border-width: 1px;
	border-style: solid;
}

.border-gray{
	border-color: #d6d6d6;
}

.border-top, .border-bottom{
	border-width: 0;
	border-style: solid;
}

.border-top{
	border-top-width: 1px;
}
.border-bottom{
	border-bottom-width: 1px;
}
